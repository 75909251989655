<template>
  <a-ConfigProvider :locale="locale">
    <div id="app" >
      <div class="banner" v-if="netWork === false">
        <div class="net"><a-icon type="disconnect" /> <span>网络已断开 </span></div>
      </div>
      <router-view > </router-view>
    </div>
  </a-ConfigProvider>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'

export default {
  data() {
    return {
      locale: zhCN,
      netWork: true
    }
  },
  mounted() {
    // 检测断网
    window.addEventListener('offline', () => {
      // this.$message.error('网络已断开')
      this.netWork = false
    })
    window.addEventListener('online', () => {
      console.log('网络已连接')
      // this.$message.success('网络已连接')
      this.netWork = true
    })
  }
}
</script>
<style>
.banner {
  width: 100%;
  height: 37px;
  background-color: #fffbe6;
  text-align: center;
  animation: animate 0.5s ease 1;
  /*  animation-fill-mode: forwards; */
}
.net {
  height: 100%;
  line-height: 37px;
  font-weight: 700;
  color: red;
}
#app {
  height: 100%;
  background-color: #f6f6f6;
}

.mask {
  overflow: hidden;
}

@keyframes animate {
  from {
    height: 0px;

    top: 0px;
  }

  to {
    height: 37px;

    top: 0px;
  }
}
</style>
