// 职责：创建一个router实例导出给main.js使用
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes: [{
            path: '/',
            name: 'index',
            component: () =>
                import ('@/views/home/index.vue')
        },

        {
            path: '/question',
            name: 'question',
            component: () =>
                // import ('@/views/question/bank.vue')
                import ('@/views/question/index.vue')
        },
        {
            path: '/question/index2',
            name: 'question2',
            component: () =>
                // import ('@/views/question/bank.vue')
                import ('@/views/question/index2.vue')
        },
        {
            path: '/end',
            name: 'end',
            component: () =>
                import ('@/views/end/index.vue'),
            alias: '/end'
        },
        {
            path: '/startExamination',
            name: 'startExamination',
            component: () =>
                import ('@/views/question/startExamination.vue')
        },
        //app考试
        {
            path: '/examByAPP',
            name: 'examByAPP',
            component: () =>
                import ('@/views/question/examByApp.vue')
        },
        //app考试
        {
            path: '/appByWork',
            name: 'appByWork',
            component: () =>
                import ('@/views/home/appByWork.vue')
        },
        {
            path: '/success',
            name: 'success',
            component: () =>
                import ('@/views/question/success.vue')
        },
        // 404
        {
            path: '*',
            name: '404',
            component: () =>
                import ('@/views/404/index.vue')
        }
    ]
})

// eslint-disable-next-line eol-last
export default router