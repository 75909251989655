import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd, { Card, Icon, Button, notification, Modal, Upload, Result, Progress } from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import './style/index.css'
import VueRouter from 'vue-router'
// import iconFront from './assets/iconfont/iconfont.js'
// const usericon = Icon.createFromIconfontCN({
//   scriptUrl: iconFront
// })
// Vue.config.productionTip = false
// Vue.component('user-icon', usericon)
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
import './utils/hljs'
import {
  parseTime
} from '@/utils/jnxh'
// import './components/wangEditor/registrar'; // 全局注册编辑器
import { StringExchangeEmoji, EmojiExchangeString } from '@/utils/emoji'
import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error)
}
Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = el.dataset.title
  }
})
Vue.prototype.parseTime = parseTime
Vue.config.productionTip = false
Vue.use(mavonEditor)
Vue.use(codemirror)
Vue.use(Antd)

Vue.use(Card)
Vue.use(Icon)
Vue.use(Button)
Vue.use(Modal)
Vue.use(Upload)
Vue.use(Result)
Vue.use(Progress)
Vue.prototype.$notification = notification
Vue.prototype.EmojiExchangeString = EmojiExchangeString
Vue.prototype.StringExchangeEmoji = StringExchangeEmoji
new Vue({
  router,
  store,
  render: (h) => h(App)
  // eslint-disable-next-line eol-last
}).$mount('#app')
