import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    la: 'saaa',
    blurCount: 0,
    scoreBOList: []
  },
  mutations: {
    setArr: (state, ans) => {
      state.scoreBOList.push(ans)
    },
    setCount: (state, ans) => {
      state.blurCount = ans
    },

  },
  actions: {
  },
  modules: {

  }
})
